import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import './content.css';
import Parties from '../../Pages/Parties/Party';
import Bookings from '../../Pages/Bookings/Bookings';
import Carriers from '../../Pages/Carriers/Carrier';
import Shipments from '../../Pages/Shipments/Shipments';
import Invoice from '../../Pages/Invoices/Invoice';
import Location from '../../Pages/Location/location'
import Settings from '../../Pages/Settings/Settings';
import DocumentationOverview from '../../Pages/Documentation/DocumentationOverview';
import ReportRouter from '../../Pages/Reports/ReportRouter';
import MyReportRouter from '../../Pages/MyReports/MyReportRouter';
import CustomerOverview from "../../Pages/Customer/CustomerOverview";
import Contracts from '../../Pages/Contracts/Contracts';
import GeneralLog from "../../Pages/GeneralLog/GeneralLog";
import FileUpload from '../FileUpload/FileUpload';
import UserOverview from "../../Pages/Parties/Users/UserOverview"
import RequestOverview from "../../Pages/Requests/RequestOverview";
import ServiceOverview from "../../Pages/ServiceRoute/ServiceRouteOverview";
import ForecastOverview from "../../Pages/Forecast/ForecastOverview";
import Carrier from "../../Pages/Carriers/Carrier";
import { urlActions } from '../../Redux/actions';


const notFound = () => {
    return <div>
        <h2> Error 404. Sorry, there's nothing here. </h2>
        <Redirect to="/shipment" />
    </div>
}


class Content extends Component {

    componentDidUpdate = (prevProps) => {
        if (this.props.url && this.props.url.url !== prevProps.url.url) {
            this.props.history.push(this.props.url.url)
        } else if (this.props.location.pathname && this.props.location.pathname !== prevProps.location.pathname) {
            this.props.dispatch({ type: urlActions.UPDATE_URL, payload: { url: this.props.location.pathname, state: { ...this.props.url.state } } })
        }
    }

    render = () => {
        return (
            <div id="master-content" className="content">
                <Switch>
                    <Route path="/party" component={Parties} />
                    <Route path="/booking" component={Bookings} />
                    <Route path="/carrier" component={Carriers} />
                    <Route path="/shipment" component={this.props.user.isEmployee ? Shipments : CustomerOverview} />
                    <Route path="/invoice" component={Invoice} />
                    <Route path="/location" component={Location} />
                    <Route path="/settings" component={Settings} />
                    <Route path="/documentation" component={DocumentationOverview} />
                    <Route path="/report" component={ReportRouter} />
                    <Route path="/myreport" component={MyReportRouter} />
                    <Route path="/customer" component={CustomerOverview} />
                    <Route path="/contract" component={Contracts} />
                    <Route path='/log' component={GeneralLog} />
                    <Route path='/files' component={FileUpload} />
                    <Route path='/useroverview' component={UserOverview} />
                    <Route path="/request" component={RequestOverview} />
                    <Route path="/service/:serviceRoute?/:carrierId?/:originName?" component={ServiceOverview} />
                    <Route path="/forecast" component={ForecastOverview} />
                    <Route path="/carrier" component={Carrier} />
                    <Route path="*" component={notFound} />
                </Switch>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const { urls, index, user } = state;
    return { url: urls[index], user };
}

export default withRouter(connect(mapStateToProps)(Content));
import navTree from "./navTree";

export const initFunctions = {
  initializeList: (state, listName, list) => {
    const newState = JSON.parse(JSON.stringify(state));
    newState[listName] = list;
    return { ...state, ...newState };
  }
};

export const tabFunctions = {
  //CREATES DEFAULT SIDEBAR OBJECT FOR NEW TAB
  // createDefaultTab: () => ({
  //     index: 0,
  //     navs: [
  //         {
  //             title: 'HOME',
  //             isHome: true,
  //             path: '/',
  //             links: [
  //                 { name: 'Home', url: '', isFiltered: false },
  //                 { name: 'Bookings', url: 'booking', isFiltered: false },
  //                 { name: 'Shipments', url: 'shipment', isFiltered: false },
  //                 { name: 'Parties', url: 'party', isFiltered: false },
  //                 { name: 'Carriers', url: 'carrier', isFiltered: false },
  //                 { name: 'Places', url: 'location', isFiltered: false }
  //             ]
  //         }
  //     ]
  // }),

  createDefaultTab: () => ({
    index: 0,
    navs: [
      {
        title: "",
        isHome: true,
        path: "/",
        links: [
          { name: "Shipment Overview", url: "shipment", isFiltered: false },
          { name: "Shipment", url: "shipment/create/ ", isFiltered: true },
          { name: "Request", url: "request", isFiltered: false },
          { name: "Documentation Overview", url: "documentation", isFiltered: false },
          { name: "Service Schedule", url: "service/", isFiltered: false },
          { name: "Forecast Tracking", url: "forecast", isFiltered: false },
          { name: "Invoicing", url: "invoice", isFiltered: false },
          { name: "Splits", url: "shipment/split", isFiltered: false },
          { name: "Party Overview", url: "party", isFiltered: false },
          { name: "Carrier Overview", url: "carrier", isFiltered: false },
          { name: "Location Overview", url: "location", isFiltered: false },
          { name: "Standalone Invoice", url: "invoice/create", isFiltered: false },
          { name: "Contracts Overview", url: "contract", isFiltered: false },
          { name: "Reports", url: "report", isFiltered: false },
          { name: "My Reports", url: "myreport", isFiltered: false }
        ]
      }
    ],
    custNavs: [
      {
        title: "",
        isHome: true,
        path: "/",
        links: [
          { name: "Bookings List", url: "customer", isFiltered: false },
          { name: "Request", url: "request", isFiltered: false }
        ]
      }
    ]
  }),

  //ADDS A NEW TAB TO THE MAIN SCREEN, IF 10 TABS ARE OPEN, DOES NOTHING.
  addTab: state => {
    const { tabCount, sidebars, urls, navs } = state;
    if (tabCount > 9) return { ...state };

    sidebars.push(tabFunctions.createDefaultTab());
    urls.push(urlFunctions.createDefaultUrl());
    navs.push(navFunctions.createDefaultNav());
    const index = tabCount;
    return { ...state, index, tabCount: tabCount + 1, sidebars, urls };
  },

  //SELECT TAB TO RIGHT UNLESS RIGHT-MOST TAB IS SELECTED.
  incrementCurrentTab: state => {
    let { index, tabCount } = state;
    if (index === tabCount - 1) return { ...state };

    index++;
    return { ...state, index };
  },

  //SELECT TAB TO LEFT UNLESS LEFT-MOST TAB IS SELECTED.
  decrementCurrentTab: state => {
    let { index } = state;
    if (index < 1) return { ...state };

    index--;
    return { ...state, index };
  },

  //SELECT TAB BY INDEX (CLICK ON TAB), CHECKS FOR VALID TAB INDEX.
  selectTab: (state, index) => {
    const { tabCount } = state;
    if (index < 0 || index >= tabCount) return { ...state };

    return { ...state, index };
  },

  //REMOVE A SELECTED TAB BY INDEX, CHECKS FOR VALID TAB INDEX.
  closeTab: (state, closeIndex) => {
    let { tabCount, index, navs, urls, sidebars } = state;
    if (closeIndex < 0 || closeIndex >= tabCount) return { ...state };

    if (tabCount < 2) return { ...state };

    navs.splice(closeIndex, 1);
    urls.splice(closeIndex, 1);
    sidebars.splice(closeIndex, 1);
    tabCount--;

    if (closeIndex <= index) index--;

    return { ...state, tabCount, index, navs, urls, sidebars };
  }
};

export const urlFunctions = {
  //CREATES DEFAULT URL -- COULD HAVE FIGURED THAT ONE OUT PROBABLY...
  createDefaultUrl: () => ({ url: "/", state: {} }),

  //Testing for new reducer function, if state is the, update cannot be called to re-search
  refreshUrl: (state, payload) => {
    const { urls, index } = state;
    urls[index] = { url: payload.url, state: payload.state };
    return { ...state, urls };
  },

  //UPDATES URL IN URLS ARRAY
  updateUrl: (state, payload) => {
    const { urls, index } = state;

    urls[index] = payload.state
      ? { url: payload.url, state: payload.state }
      : { url: payload, state: {} };

    return { ...state, urls };
  }
};

export const sidebarFunctions = {
  //GOES FORWARD IN THE SIDEBAR NAV ARRAY, CHECKS IF IS LAST NAV
  nextNav: state => {
    const { sidebars, index } = state;
    if (sidebars[index].index === sidebars[index].navs.length - 1) return;

    const copy = JSON.parse(JSON.stringify(sidebars));
    copy[index].index++;

    return { ...state, sidebars: copy };
  },

  //GOES BACK IN THE SIDEBAR NAV ARRAY, CHECKS IF IS FIRST NAV
  prevNav: state => {
    const { sidebars, index } = state;
    if (sidebars[index].index < 1) return;

    const copy = JSON.parse(JSON.stringify(sidebars));
    copy[index].index--;

    return { ...state, sidebars: copy };
  },

  //REBUILDS ENTIRE NAVS SECTION OF SIDEBAR ON PAGE RENDER
  rebuildNav: (state, payload) => {
    let { url } = payload;
    const { sidebars, index } = state;

    const newSidebars = JSON.parse(JSON.stringify(sidebars));
    const newNavs = [navTree.home];

    url = url.split("/");
    url.forEach(endPoint =>
      endPoint ? newNavs.push(navTree[endPoint]) : null
    );
    newSidebars[index].navs = newNavs;
    newSidebars[index].index = newNavs.length - 1;

    return { ...state, sidebars: newSidebars };
  }
};

export const navFunctions = {
  //CREATES DEFAULT NAV OBJECT
  createDefaultNav: () => ({
    shipperId: 1,
    filterByShipper: false
  }),

  //CHANGES SHIPPER ID
  changeId: (state, shipperId) => {
    const { navs, index } = state;
    const copy = JSON.parse(JSON.stringify(navs));
    copy[index].shipperId = shipperId;

    return { ...state, navs: copy };
  },

  //CHANGES THE CONTRACT NUMBER
  changeContract: (state, contractNumber) => {
    const { navs, index } = state;
    const copy = JSON.parse(JSON.stringify(navs));
    copy[index].contractNumber = contractNumber;

    return { ...state, navs: copy }

  },

  //SETS FILTER BY SHIPPER TO TRUE OR FALSE
  setFilter: (state, filterByShipper) => {
    const { navs, index } = state;
    const copy = JSON.parse(JSON.stringify(navs));
    copy[index].filterByShipper = filterByShipper;

    return { ...state, navs: copy };
  },

  //UPDATE SIDEBAR SEARCH VALUE
  setSearchTerm: (state, sidebarSearchTerm) => {
    const { navs, index } = state;
    const copy = JSON.parse(JSON.stringify(navs));
    copy[index].sidebarSearchTerm = sidebarSearchTerm;

    return { ...state, navs: copy }
  }

};

export const globalToastFunctions = {
  updateMsg: (state, globalToast) => {
    return { ...state, globalToast };
  }
};

export const updateFunctions = {
  updateVar: (state, sessionVariables) => {
    return { ...state, sessionVariables }
  }
}
